import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Euforia = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #794B9B, #8248B1, #9452C8, #8248B1, #794B9B )",
        icons: "#A26DCD",
        navClass: "euforia",
      }}
      seo={{
        title: "Perfumeria Euforia",
        headerTitle: "euforia",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/euforia_top_back.png"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/euforia/",
      }}
    >
      <PortfolioHeader name="euforia" height="88" />
      <section className="container-fluid euforia_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Euphoria Perfumery</h1>
              <ul>
                <li>Product Marketing Campaign</li>
                <li>Case Study</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Perfumeria Euforia is a well-known Polish online store offering,
                among others, original perfumes and cosmetics. The brand has
                been on the market since 2009, and since then has been
                successfully selling drugstore products, expanding its range
                each range.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid euforia_section_3">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h4>Problem</h4>
            <p>
              As with all e-commerce businesses, the main goal of the campaign
              is to increase sales - and that's exactly the issue with our
              Client, who came to us in April of 2017. Google Ads campaign ran
              by another agency generated transactions, but they were not
              satisfactory for customers - online perfumeries are very popular
              and the products offered are known to the target customers, so
              they are looking for a store that will offer the products at the
              lowest price. Hence the problem is the low margin imposed on these
              products, and thus a much lower return on investment than for
              other industries. The solution to this situation should therefore
              be to reach the highest possible revenue from the campaign at the
              lowest possible cost.
            </p>
            <h4>Solution</h4>
            <p>
              When creating the campaign, we focused primarily on product
              campaigns, which allow images and short product descriptions to be
              displayed in Google search engine and are best suited to achieve
              high sales with a small investment. Product campaigns are also
              supported by dynamic remarketing (re-display of products that the
              user was previously interested in and enabling the completion of
              the purchasing process) and a campaign in the search network for
              the brand phrases. From the very beginning product campaigns
              showed us high effectiveness, however, they required constant
              observation and optimization. In July 2018 we decided to use
              Google News (beta) available for campaigns that meet certain
              conditions, i.e. an intelligent product campaign. It is a highly
              automated campaign subtype that combines a product and remarketing
              campaign into one. Regardless of small difficulties at the
              beginning of the tests, it turned out that the smart campaign was
              a great success that brought better and better results every day
            </p>
            <p>
              Kampanie produktowe od samego początku wykazywały zdecydowaną
              skuteczność, jednak wymagały stałej obserwacji i optymalizacji. W
              lipcu 2018 r. podjęliśmy decyzję o skorzystaniu z nowości Google
              (bety) dostępnej dla kampanii spełniających określone warunki,
              czyli z inteligentnej kampanii produktowej. Jest to mocno
              zautomatyzowany podtyp kampanii, łączący kampanię produktową i
              remarketingową w jedną. Pomimo małych utrudnień na początku
              testów, okazało się, że kampania inteligentna była strzałem w
              dziesiątkę – z dnia na dzień przynosiła coraz lepsze efekty.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid euforia_section_4">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <div className="top">
              <h4>Result of our work</h4>
              <p>
                Comparison of two corresponding monthly campaign periods:
                activities of the previous agency that our Client worked with
                and the activities of our Agency:{" "}
              </p>
              <img
                src={require("../../../assets/img/portfolio/euforiatop.png")}
                alt=""
              />
            </div>
            <div className="row bottom">
              <TrackVisibility
                once
                offset={300}
                className="col-md-6 list__container"
              >
                {({ isVisible }) => (
                  <ul className="list">
                    <li>
                      <span>
                        Revenue increase by o{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 4318 : 0}
                        />
                        %
                      </span>
                    </li>
                    <li>
                      <span>
                        Amount of transactions increased by{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 4075 : 0}
                        />
                        %
                      </span>
                    </li>
                    <li>
                      <span>
                        Conversion rate increased from 1% to{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 5.2 : 0}
                        />
                        %
                      </span>
                    </li>
                    <li>
                      <span>
                        ROAS increase from{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 213 : 0}
                        />
                        % to{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 3358 : 0}
                        />
                        %
                      </span>
                    </li>
                    <li>
                      <span>
                        Decrease in costs of achieving revenue from{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 47 : 0}
                        />
                        % to{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 3 : 0}
                        />
                        %
                      </span>
                    </li>
                  </ul>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-md-6">
                {({ isVisible }) => (
                  <>
                    <p>
                      During this period, our campaign was also the main source
                      of traffic and revenue for the website. All conversions
                      that contributed to the Google Ads campaign were over{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 3000 : 0}
                      />{" "}
                      and their value amounted to almost{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 500000 : 0}
                      />{" "}
                      zł.
                    </p>
                    <p>
                      Work done for this Client allowed us to open up to a new
                      type of Allegro Ads and to test the functioning of
                      intelligent Google Ads campaigns. As a result, we have
                      confirmed their effectiveness and are gradually
                      transforming other e-commerce campaigns that we run into
                      intelligent ones as well.
                    </p>
                  </>
                )}
              </TrackVisibility>
            </div>
            <div className="row justify-content-center">
              <div className="col">
                <img
                  src={require("../../../assets/img/portfolio/euforia1.png")}
                  alt=""
                />
                <span className="photo-info">
                  The results of the previous campaign Google Ads. Source:
                  Google Analytics.
                </span>
                <img
                  src={require("../../../assets/img/portfolio/euforia2.png")}
                  alt=""
                />
                <span className="photo-info">
                  The results of the Google Ads search campaign run by the When
                  agency. Source: Google Analytics.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid euforia_section_5">
        <div className="row justify-content-center">
          <TrackVisibility once offset={300} className="col-md-8">
            {({ isVisible }) => (
              <>
                <h4>Bonus</h4>
                <p>
                  In addition, we ran the Allegro Ads campaign for Perfumeria
                  Euforia during the Christmas season. This is an interesting
                  support for Google Ads campaigns when it comes to stores that
                  sell their products through Allegro. The Allegro Ads campaign
                  lasted{" "}
                  <CountUp start={0} duration={3} end={isVisible ? 15 : 0} />{" "}
                  days and with a test budget of{" "}
                  <CountUp start={0} duration={3} end={isVisible ? 514 : 0} />{" "}
                  PLN, we received{" "}
                  <CountUp start={0} duration={3} end={isVisible ? 158 : 0} />{" "}
                  sold units with value of{" "}
                  <CountUp start={0} duration={3} end={isVisible ? 16602 : 0} />{" "}
                  PLN. It should be remembered, however, that conversions in
                  this tool are calculated in a different way than in Google
                  Analytics (where transactions from the last click are taken
                  into account), and the items sold are the total number of
                  offers sold counted for the next{" "}
                  <CountUp start={0} duration={3} end={isVisible ? 7 : 0} />{" "}
                  days after clicking on the Allegro advertisement Ads.
                  Therefore, it is not worth comparing this with the actual
                  results of Google Ads campaigns.
                </p>
                <p>
                  The presented case study regards a marketing project ran by
                  the WHEN Digital Marketing Agency - a part of the ADream Group
                </p>
              </>
            )}
          </TrackVisibility>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Euforia;
